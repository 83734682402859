<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, computed } from 'vue'
import { chunkArray } from '@/utils/chunkArray'

const props = defineProps({
  itemList: {
    type: Array as PropType<unknown[]>,
    required: true,
  },
  pageSize: {
    type: Number,
    required: true,
  },
  scrollTo: {
    type: String,
    required: false,
    default: '',
  },
})

const currentPage = ref(0)
const onChange = (pageNumber: number) => {
  const el = document.getElementById(props.scrollTo)
  if (el) el.scrollIntoView({ behavior: 'smooth' })
  setTimeout(() => { currentPage.value = pageNumber - 1 }, 300)
}
const splitItems = computed(() => chunkArray(props.itemList, props.pageSize))
const items = computed(() => splitItems.value[currentPage.value])
</script>

<template>
  <slot :items="items" />
  <div class="flex justify-center col-span-full">
    <ElPagination
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      :total="itemList.length"
      hide-on-single-page
      @current-change="onChange"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-pagination button) {
  border: 1px solid #023f87;
  border-radius: 8px;
  width: 56px;
  height: 35px;

  .el-icon {
    color: #023f87;
  }

  &:hover {
    background-color: #023f87;
    & .el-icon {
      color: #fff;
    }
  }

  .el-icon {
    font-size: 24px;
    margin-left: 9px;

    svg {
      margin: 0;
    }
  }

  li {
    &.is-active {
      background-color: none;
    }
  }
}

:deep(.el-pager li) {
  background: none;
  font-size: 24px;
  color: #023f87;
}
</style>
